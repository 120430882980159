import React from 'react';

export default function VideoHero({ srcWebm, srcMp4, srcOgg, children }) {
  const videoRef = React.useRef();
  const containerRef = React.useRef();
  const observerRef = React.useRef();

  React.useEffect(() => {
    if (window.matchMedia('(prefers-reduced-motion)').matches) {
      videoRef.current.removeAttribute('autoplay');

      videoRef.current.pause();

      return;
    }

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: [0],
    };

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoRef.current?.play();
        } else {
          videoRef.current?.pause();
        }
      });
    };

    observerRef.current = new IntersectionObserver(handleIntersect, options);

    observerRef.current.observe(containerRef.current);

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = null;
    };
  }, [containerRef, videoRef]);

  return (
    <section
      className="w-screen h-screen overflow-hidden relative"
      ref={containerRef}
    >
      <div className="absolute inset-0">
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          disablePictureInPicture
          className="min-w-full min-h-full object-cover object-center"
        >
          {srcWebm && <source src={srcWebm} type="video/webm" />}
          {srcOgg && <source src={srcOgg} type="video/ogg" />}
          {srcMp4 && <source src={srcMp4} type="video/mp4" />}
        </video>
      </div>

      <div className="absolute inset-0 pointer-events-none z-10 flex justify-center items-center bg-indigo/25">
        <div className="container">{children}</div>
      </div>
    </section>
  );
}
