import classNames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import CTA from '../components/CTA';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import MediaText from '../components/MediaText';
import SquareMediaText from '../components/SquareMediaText';
import TestimonyCarouselGrid from '../components/TestimonyCarouselGrid';
import VideoHero from '../components/VideoHero';
import defaultPicture from '../images/PellerinGuitares_110.jpeg';
import introVideoMp4 from '../videos/intro_nhd-500k.mp4';
import introVideoWebm from '../videos/intro_nhd-200k.webm';

const translations = [
  {
    locale: 'fr',
    url: '/',
  },
];

const headerTitleStyle = {
  fontSize:
    'clamp(1.75rem, calc(1.75rem + (5.5 - 1.75) * ((100vw - 20rem) / (98 - 20))), 5.5rem)',
  lineHeight: 'calc((96 / 88) * 1em)',
};

// markup
export default function HomePage({ data, location }) {
  return (
    <Layout translations={translations} location={location}>
      <article className="space-y-48">
        <VideoHero srcWebm={introVideoWebm} srcMp4={introVideoMp4}>
          <p
            style={headerTitleStyle}
            className={classNames([
              'text-white text-center uppercase font-light',
            ])}
          >
            Where Art <br />
            Vibrates with <br />
            Music
          </p>
        </VideoHero>

        <section className="container text-xl text-center min-h-[68vh] flex items-center justify-center">
          <div>
            <Heading level={2} className="text-5xl">
              Your guitar and your art <br />
              in perfect unison
            </Heading>

            <p className="max-w-prose my-4">
              Since you are one of a kind, you deserve a custom-made guitar that
              will resonate with you. One with a design, sound quality and
              balance that will have you saying, “At last, I have found the
              guitar of my dreams!”
            </p>

            <UnderlinedButton to="/en/custom-made-guitars/" className="my-5">
              Treat yourself to a custom-made guitar
            </UnderlinedButton>
          </div>
        </section>

        <MediaText left top media={data.pellerinSoundImage}>
          <div className="max-w-prose">
            <Heading level={2} className="!mt-0">
              The Pellerin sound: <br />
              in harmony with excellence
            </Heading>

            <p>
              Discover the ideal guitar with perfectly balanced high and low
              frequencies. Pellerin guitars are crafted from carefully selected
              top-quality materials and are handcrafted with painstaking care;
              this is what gives them their world-class sound quality.
            </p>
          </div>
        </MediaText>

        <CTA>
          <Heading level={2} className="!mt-0">
            Guitars that rock
          </Heading>

          <p className="max-w-prose my-4">
            Over the years, Michel Pellerin and his business partner François
            C.Paradis have created more than 250 distinctive guitars — or “works
            of art”, which may be a more appropriate term. Here is a quick look
            at some of the most beautiful Pellerin guitars.
          </p>

          <UnderlinedButton to="/en/creations/" className="text-xl my-5">
            Check out the guitars
          </UnderlinedButton>
        </CTA>

        <section>
          <div className="container text-xl text-center">
            <div>
              <Heading level={2} className="text-5xl">
                Just the right touch for music lovers
              </Heading>

              <p className="max-w-prose mx-auto my-5">
                Anyone who owns a Pellerin guitar knows well that it is not just
                your run-of-the-mill guitar; it’s the extension of their very
                soul and spirit. A precious gem that offers no less than an
                incredible experience from start to finish.
              </p>
            </div>
          </div>

          <TestimonyCarouselGrid className="my-12" />

          <div className="container text-center">
            <UnderlinedButton to="/en/testimonials/" className="text-xl my-5">
              Read what customers have said
            </UnderlinedButton>
          </div>
        </section>

        <section className="container">
          <SquareMediaText
            src={defaultPicture}
            className="wide-content-contained"
            media={data.readyToPlayImage.childImageSharp}
          >
            <Heading level={2} className="!mt-0">
              Ready-to-play guitars to jazz up your life
            </Heading>

            <p>
              Michel Pellerin is passionate about making his exceptional guitars
              and even takes pleasure in making more in his spare time. Want a
              fantastic guitar without waiting 2 years for a custom one? No
              worries: check out the sublime guitars in stock!
            </p>

            <UnderlinedButton
              to="/en/ready-to-play-guitars/"
              className="text-xl"
            >
              Check out the guitars in stock
            </UnderlinedButton>
          </SquareMediaText>

          <SquareMediaText
            src={defaultPicture}
            className="wide-content-contained"
            media={data.reputationImage.childImageSharp}
            left
          >
            <Heading level={2} className="!mt-0">
              A master who has perfected his craft since&nbsp;1998
            </Heading>

            <p>
              Guitar maker Michel Pellerin is at the top of his game. In his
              workshop in Thetford Mines, Quebec, surrounded by wood, strings
              and tools, he is hard at work. His mission is simple: to create a
              unique, high-quality instrument that will spring to life in your
              hands.
            </p>

            <UnderlinedButton to="/en/about/" className="text-xl">
              See the artisan at work
            </UnderlinedButton>
          </SquareMediaText>
        </section>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query {
    pellerinSoundImage: file(name: { eq: "PellerinGuitares_040" }) {
      childImageSharp {
        gatsbyImageData(width: 1536, placeholder: BLURRED)
      }
    }

    readyToPlayImage: file(name: { eq: "PellerinGuitares_025" }) {
      childImageSharp {
        gatsbyImageData(width: 768, height: 768, placeholder: BLURRED)
      }
    }

    reputationImage: file(name: { eq: "PellerinGuitares_134" }) {
      childImageSharp {
        gatsbyImageData(width: 768, height: 768, placeholder: BLURRED)
      }
    }
  }
`;
